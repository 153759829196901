<template>
  <div class="photos-wrapper">
    <p class="construction">This page is under construction!</p>
  </div>

</template>

<script>
export default {

}
</script>

<style>

.photos-wrapper {
  text-align: center;
  margin: auto;
}

p .construction {
  text-align: center;
}

</style>