<template>
  <p>This page is also under construction!</p>
</template>

<script>
export default {

}
</script>

<style>

p {
  text-align: center;
}

</style>