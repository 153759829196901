<template>
  <div class="resume">
    <div class="section">
      <h1>Education</h1>
      <h2>Duke University</h2>
      <p class="date-location">Class of 2023 (Durham, NC)</p>
      <h3>B.S. in Computer Science, Minors in Linguistics and French</h3>
      <ul class="bullets">
        <li>Cumulative GPA: 3.7</li>
        <li>Relevant Coursework: Software Design and Implementation, Intro to Database Systems, Data Structures and Algorithms, Computer Architecture, Intro to Operating Systems, Functional Programming, Computer Security</li>
        <li>Club Volleyball Team, Club Ski Team, K-Ville Tent Captain</li>
      </ul>
      <h2>Duke in France/EDUCO</h2>
      <p class="date-location">Fall 2022 (Paris, France)</p>
      <h3>Study Abroad Student</h3>
      <ul class="bullets">
        <li>French immersion program, including a Functional Programming course</li>
        <li>Navigated cultural differences and living with a French host family</li>
        <li>Organized group and independent excursions to various international destinations</li>
      </ul>
    </div>

    <div class="section">
      <h1>Work Experience</h1>
      <h2>Duke Technical Servies</h2>
      <p class="date-location">Summer 2019 - May 2023 (Durham, NC)</p>
      <h3>Student Technician</h3>
      <ul class="bullets">
        <li>Organized and set up audio and visual systems for on campus events</li>
        <li>Operated sound systems for different occasions including high profile events such as men’s basketball games for the duration of the season</li>
        <li>Trained new technicians through shadowing and hands-on experience</li>
      </ul>
      <h2>Juni Learning</h2>
      <p class="date-location">January - April 2021 (Virtual)</p>
      <h3>Computer Science Instructor</h3>
      <ul class="bullets">
        <li>Taught fundamental programming concepts and problem-solving skills through one-on-one lessons with students using Scratch and Python</li>
        <li>Adapted lesson plans to be best suited for individual learners and designed projects to be based on the student’s interests</li>
      </ul>
    </div>

    <div class="section">
      <h1>Skills and Interests</h1>
      <h3>Tech Skills</h3>
      <ul>
        <li>Python, Java, JavaScript, Vue.js, HTML, CSS, C/C++, OCaml, Agile/Scrum, Adobe Creative Suite</li>
      </ul>
      <h3>General Skills</h3>
      <ul>
        <li>French (advanced proficiency), creative problem solving, open-mindedness, critical thinking, holistic approach to projects</li>
      </ul>
      <h3>Interests</h3>
      <ul>
        <li>Language, chess, reading, running, film photography, drone videography</li>
      </ul>
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style>

.resume {
  text-align: left;
  align-content: center;
  width: 60%;
  margin: auto;
  font-size: large;
}

h1 {
  text-transform: uppercase;
  text-align: center;

}

p {
  text-align: left;
}

li {
  padding-bottom: 0.5em;
}

</style>